/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'glightbox/src/postcss/glightbox.css'
import 'locomotive-scroll/dist/locomotive-scroll.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

/* Import Js Plugins (ES6 Module) and custom JS */
import GLightbox from 'glightbox';
import LazyLoad from 'vanilla-lazyload';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';
import { Modal } from 'flowbite';

gsap.registerPlugin(ScrollTrigger);

import '../plugins/cookieconsent-init';

const xs = window.matchMedia('(max-width: 390px)');
const sm = window.matchMedia('(max-width: 767px)');
const md = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
const lg = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');

const UTILS = {
    init() {
        this.locomotiveScroll();
        this.loadingScreen();
        this.heroAnimation();
        this.gLightbox();
        this.lazyLoad();
        this.topNavigationMenu();
        this.copyPrintYear();
    },
    locomotiveScroll() {
        let scrollContainer = document.querySelector('[data-scroll-container]');
        const locomotiveScroll = new LocomotiveScroll({
            el: scrollContainer,
            smooth: true,
            resetNativeScroll: true,
            lerp: 0.05,
            tablet: {
                smooth: true
            },
            smartphone: {
                smooth: true
            }
        });
        locomotiveScroll.on('scroll', (instance) => {
            ScrollTrigger.update();
            document.documentElement.setAttribute('data-scrolling', instance.direction);
        });
        ScrollTrigger.scrollerProxy(scrollContainer, {
            scrollTop( value ) {
                return arguments.length ? locomotiveScroll.scrollTo( value, 0, 0 ) : locomotiveScroll.scroll.instance.scroll.y;
            },
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
            },
            pinType: document.querySelector('[data-scroll-container]').style.transform ? 'transform' : 'fixed'
        });

        let primaryNavAnchors =  document.querySelectorAll('.pu__primary-nav ul li span');
        primaryNavAnchors.forEach((anchor) =>  {
            anchor.addEventListener('click', (e) => {
                if (! document.querySelector('.pu__primary-nav').classList.contains('hidden')) {
                    document.getElementById('close-menu-button').click();
                }
                locomotiveScroll.scrollTo(e.currentTarget.dataset.anchor, {
                    offset: 150
                });
            });
        });

        ScrollTrigger.addEventListener('refresh', () => locomotiveScroll.update());
        ScrollTrigger.refresh();
    },
    loadingScreen() {
        let tl = gsap.timeline({
            onComplete () {
                // add classes to animate header
                document.querySelector('.pu__social-nav').classList.add('animate__fadeInDown');
                document.getElementById('toggle-menu-button').classList.add('animate__fadeInDown');
                document.getElementById('toggle-menu').classList.add('animate__fadeInDown');
            }
        });
        if (xs.matches) {
            tl
                .to('.pu__loading-screen figure figcaption span', { opacity: 0, duration: 0.3 })
                .to('.pu__loading-screen .pu__spinner-wrap .pu__spinner', { opacity: 0, duration: 0.3 }, 0)
                .to('.pu__loading-screen figure img.trademark', { width: 'auto', height: '30px', translateY: '-20px', duration: 1, ease: 'none' }, 0)
                .to('.pu__loading-screen', { opacity: 0, duration: 0.5, delay: 0.2 })
                .to('header', { display: 'grid', duration: 0.5 }, 0)
                .to('.pu__loading-screen', { display: 'none', delay: 0.2 });
        } else if (sm.matches) {
            tl
                .to('.pu__loading-screen figure figcaption span', { opacity: 0, duration: 0.3 })
                .to('.pu__loading-screen .pu__spinner-wrap .pu__spinner', { opacity: 0, duration: 0.3 }, 0)
                .to('.pu__loading-screen figure img.trademark', { width: 'auto', height: '30px', translateY: '-20px', duration: 1, ease: 'none' }, 0)
                .to('.pu__loading-screen', { opacity: 0, duration: 0.5, delay: 0.2 })
                .to('header', { display: 'grid', duration: 0.5 }, 0)
                .to('.pu__loading-screen', { display: 'none', delay: 0.2 });
        } else if (md.matches) {
            tl
                .to('.pu__loading-screen figure figcaption span', { opacity: 0, duration: 0.3 })
                .to('.pu__loading-screen .pu__spinner-wrap .pu__spinner', { opacity: 0, duration: 0.3 }, 0)
                .to('.pu__loading-screen figure img.trademark', { width: 'auto', height: '40px', translateY: '-412px', duration: 1.5, ease: 'none' }, 0)
                .to('.pu__loading-screen', { opacity: 0, duration: 0.5, delay: 0.2 })
                .to('header', { display: 'grid', duration: 0.5 }, 0)
                .to('.pu__loading-screen', { display: 'none', delay: 0.2 });
        } else {
            tl
                .to('.pu__loading-screen figure figcaption span', { opacity: 0, duration: 0.3 })
                .to('.pu__loading-screen .pu__spinner-wrap .pu__spinner', { opacity: 0, duration: 0.3 }, 0)
                .to('.pu__loading-screen figure img.trademark', { width: 'auto', height: '40px', translateY: '-402px', duration: 1.5, ease: 'none' }, 0)
                .to('.pu__loading-screen', { opacity: 0, duration: 0.5, delay: 0.2 })
                .to('header', { display: 'grid', duration: 0.5 }, 0)
                .to('.pu__loading-screen', { display: 'none', delay: 0.2 });
        }
    },
    heroAnimation() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.pu__hero',
                pin: true,
                start: '+=0',
                //end: '+=900',
                end: '500% top',
                scrub: true,
                scroller: '[data-scroll-container]',
                // markers: true
            }
        });
        if (sm.matches) {
            tl.addLabel('start tl')
                .to('.pu__hero h1', { scale: 0.9, autoAlpha: 0, delay: 0.5 })
                .to('.pu__hero h1', { display: 'none', delay: 0.5 }, 0)
                .to('.wrap-main-image', { width: '90%', marginLeft: '-45%', bottom: '350px', opacity: 1, delay: 0.7 }, 0)
                .to('.wrap-main-image', { scale: 0.5, opacity: 0, delay: 1 })
                .to('.pu__hero h2', { opacity: 1, delay: 1.2 }, 1)
                .to('.pu__hero h2', { scale: 0.9, opacity: 0, delay: 2 }, 2)
                .to('.pu__hero', { backgroundColor: 'white', delay: 2.2 }, 2)
                .to('.pu__hero figure.scroll-down', { opacity: 0, delay: 2.6 }, 2)
                .addLabel('end tl');
        } else if (md.matches) {
            tl.addLabel('start tl')
                .to('.pu__hero h1', { scale: 0.9, autoAlpha: 0, delay: 0.5 })
                .to('.pu__hero h1', { display: 'none', delay: 0.5 }, 0)
                .to('.wrap-main-image', { width: '80%', marginLeft: '-40%', bottom: '300px', opacity: 1, delay: 0.7 }, 0)
                .to('.wrap-main-image', { scale: 0.5, opacity: 0, delay: 1 })
                .to('.pu__hero h2', { opacity: 1, delay: 1.2 }, 1)
                .to('.pu__hero h2', { scale: 0.9, opacity: 0, delay: 2 }, 2)
                .to('.pu__hero', { backgroundColor: 'white', delay: 2.2 }, 2)
                .to('.pu__hero figure.scroll-down', { opacity: 0, delay: 2.6 }, 2)
                .addLabel('end tl');
        } else if (lg.matches) {
            tl.addLabel('start tl')
                .to('.pu__hero h1', { scale: 0.9, autoAlpha: 0, delay: 0.5 })
                .to('.pu__hero h1', { display: 'none', delay: 0.5 }, 0)
                .to('.wrap-main-image', { width: '70%', marginLeft: '-35%', bottom: '150px', opacity: 1, delay: 0.7 }, 0)
                .to('.wrap-main-image', { scale: 0.5, opacity: 0, delay: 1 })
                .to('.pu__hero h2', { opacity: 1, delay: 1.2 }, 1)
                .to('.pu__hero h2', { scale: 0.9, opacity: 0, delay: 2 }, 2)
                .to('.pu__hero', { backgroundColor: 'white', delay: 2.2 }, 2)
                .to('.pu__hero figure.scroll-down', { opacity: 0, delay: 2.6 }, 2)
                .addLabel('end tl');
        } else {
            tl.addLabel('start tl')
                .to('.pu__hero h1', { scale: 0.9, autoAlpha: 0, delay: 0.5 })
                .to('.pu__hero h1', { display: 'none', delay: 0.5 }, 0)
                .to('.wrap-main-image', { width: '70%', marginLeft: '-35%', bottom: '80px', opacity: 1, delay: 0.7 }, 0)
                .to('.wrap-main-image', { scale: 0.5, opacity: 0, delay: 1 })
                .to('.pu__hero h2', { opacity: 1, delay: 1.2 }, 1)
                .to('.pu__hero h2', { scale: 0.9, opacity: 0, delay: 2 }, 2)
                .to('.pu__hero', { backgroundColor: 'white', delay: 2.2 }, 2)
                .to('.pu__hero figure.scroll-down', { opacity: 0, delay: 2.6 }, 2)
                .addLabel('end tl');
        }

        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.pu__hero',
                pin: true,
                start: '+=20',
                end: '300% bottom',
                // markers: true,
                scrub: true,
                scroller: '[data-scroll-container]'
            },
            onComplete() {
                if (sm.matches) {
                    document.querySelector('.pu__services').style.left = '2.5rem';
                }
            }
        });
        tl2.addLabel('start tl2')
            .to('.pu__hero figure.shape img', { scale: 3, rotate: '180deg' })
            .to('.pu__hero figure.shape img', { scale: 10, rotate: '180deg' })
            .to('.pu__hero figure.shape img', { display: 'none', delay: 0.5 }, 0)
            .addLabel('end tl2');

        let tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: '.pu__services',
                pin: true,
                start: '+=0',
                end: '100% top',
                // markers: true,
                scrub: true,
                scroller: '[data-scroll-container]'
            }
        });
        tl3.addLabel('start tl3')
            .to('.pu__services', { opacity: 1 })
            .addLabel('end tl3');
    },
    gLightbox() {
        GLightbox({
            touchNavigation: true
        });
    },
    lazyLoad() {
        new LazyLoad();
    },
    topNavigationMenu() {
        let toggleMenuButton = document.querySelector('#toggle-menu-button');
        let menu = document.querySelector('#toggle-menu');
        let openMenuButton = document.querySelector('#open-menu-button');
        let closeMenuButton = document.querySelector('#close-menu-button');
        let itemMenu = document.querySelectorAll('#toggle-menu a');

        toggleMenuButton.addEventListener('click', (e) => {
            menu.classList.toggle('hidden');
            openMenuButton.classList.toggle('hidden');
            closeMenuButton.classList.toggle('hidden');
        });
        itemMenu.forEach((el, index) => {
            el.addEventListener('click', (e) => {
                e.preventDefault()
                toggleMenuButton.click();
            });
        });
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    }
}

export { UTILS }