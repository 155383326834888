'use strict';

/* Import CSS Plugins and main SCSS */
import 'normalize.css/normalize.css'
import '../css/main.scss';

/* Import custom JS */
import { HANDLES } from './commons/handles';
import { UTILS } from './utils/utils';

window.h5 = {
    init: () => {
        UTILS.init();
        HANDLES.init();
    }
};

window.onload = () => {
    setTimeout((e) => {
        document.querySelector('.pu__loading-screen figure').style.display = 'block';
    }, 100);
    setTimeout((e) => {
        window.h5.init();
    }, 3000);
};

window.addEventListener('resize', () => {
    window.location.reload();
});